<template>
  <div>
    <h2 class="section-title" v-if="data.title">{{ data.title }}</h2>
    <div class="companies" v-if="data.list && data.list.length">
      <a :href="'https://' + item.link" class="company-item" target="_blank" rel="nofollow" v-for="item in data.list" :key="item.link">
        <img :src="require(`@/assets/images/${item.img}.png`)" v-if='item.img' class="company-item__img" :alt="item.img" :class="item.imgClass ? item.imgClass : ''">
        <span class="company-item__description" v-if="item.text">{{ item.text }}</span>
        <span class="company-item__link" v-if="item.link">{{ item.link }}</span>
      </a>
    </div>
  </div>
</template>

<script>


export default {
  name: "Companies-block",
  props: {
    data: Object
  },
}
</script>

<style lang="scss">
.companies {
  display: flex;
  margin-bottom: 70px;

  @media (max-width: $viewport-tablet) {
    flex-wrap: wrap;
  }

  @media (max-width: $viewport-mobile) {
    margin-bottom: 0;
  }
}

.company-item {
  width: 33%;
  padding: 35px 50px 50px;
  box-sizing: border-box;
  transition: background-color .2s ease-in-out;

  @media (max-width: $viewport-tablet) {
    width: 50%;
  }

  @media (max-width: $viewport-tablet-small) {
    padding: 30px;
  }

  @media (max-width: $viewport-mobile) {
    width: 100%;
    background-color: #F1F1F1;
    margin-bottom: 1px;
  }

  &:hover {
    background-color: #F1F1F1;

    .company-item__link {
      opacity: 1;
    }
  }
}

.company-item__img {
  height: 47px;

  @media (max-width: $viewport-tablet-small) {
    height: 40px;
  }

  &--high {
    height: 57px;
    margin-top: -10px;

    @media (max-width: $viewport-tablet-small) {
      height: 50px;
      margin-top: -7px;
    }
  }
}

.company-item__description {
  display: block;
  margin: 40px 0;
  font-size: 0.875rem;
  line-height: 2.714285714;
  color: #000000;

  @media (max-width: $viewport-tablet-small) {
    line-height: 2;
  }

  @media (max-width: $viewport-mobile) {
    margin: 25px 0;
  }
}

.company-item__link {
  display: inline-block;
  position: relative;
  padding-right: 85px;
  font-weight: bold;
  font-size: 0.75rem;
  color: #000;
  opacity: 0;
  transition: opacity .2s ease-in-out;

  @media (max-width: $viewport-mobile) {
    opacity: 1;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 12px;
    height: 12px;
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.016 4.926a.73.73 0 00-.228-.527L7.618.229C7.451.069 7.28 0 7.102 0c-.406 0-.698.286-.698.673 0 .203.082.374.21.501l1.427 1.448 1.841 1.682-1.472-.09H.717c-.425 0-.717.293-.717.712 0 .412.292.704.717.704h7.694l1.472-.088-1.84 1.682L6.613 8.67a.699.699 0 00-.21.501c0 .388.293.673.699.673.178 0 .35-.07.501-.216l4.184-4.183a.728.728 0 00.228-.52z' fill='%23000'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }
}
</style>
