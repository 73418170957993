<template>
  <div>
    <v-slider
        :ref-name="'achievementsSlider'"
        :slides="slides"
        :slider-class="'achievements-slider'"
        :slide-class="'achievements-slide'"
        class="achievements-slider-container">
      <template v-slot:slide="slideContent">
        <div class="achievements-slide__box">
          <div class="achievements-slide__title">{{ slideContent.slide.title }}</div>
          <div class="achievements-slide__number">{{ slideContent.slide.number }}</div>
        </div>
      </template>
    </v-slider>
  </div>
</template>

<script>
import Slider from '@/components/Slider/Slider'

export default {
  name: "Achievements-slider",
  data(){
    return {
      slides: [
        {
          title: 'We have developed a website for Concert.ua. Number #1 in Ukraine. Online platform for buying and selling event tickets for concerts, festivals, sports events, theatre, etc',
          number: '01',
        },
        {
          title: 'Rozetka – TOP 1 E-commerce platform in Ukraine. Business migrates from e-commerce platform to the marketplace. Covers logistic, delivery, vendors support, website platform and iOS and Android applications',
          number: '02',
        },
        {
          title: 'Anticorruption organization #1 – Transparency International. We built the public interface with the options to submit and request, views accidents map, accidents history review and categories search.',
          number: '03'
        }
      ]
    }
  },
  components: {
    'v-slider': Slider
  }
}
</script>

<style lang="scss">
.achievements-slider-container {
  position: relative;
  margin-top: 70px;

  @media (max-width: $viewport-tablet) {
    margin-top: 55px;
  }

  @media (max-width: $viewport-mobile) {
    margin-top: 45px;
  }
}

.swiper-container.achievements-slider {
  max-width: 1100px;
  align-items: stretch;
  height: 100%;
  margin-left: 0;
  background: #fff;
  overflow: visible;

  @media (max-width: $viewport-desktop) {
    max-width: 800px;
  }

  @media (max-width: $viewport-tablet-small) {
    max-width: 450px;
  }

  @media (max-width: $viewport-mobile) {
    max-width: 280px;
  }

  .swiper-slide-active {
    background-color: #F1F1F1;

    .achievements-slide__number {
      color: #fff;
    }
  }
}

.swiper-slide.achievements-slide {
  height: auto;
  transition: background-color .2s ease-in-out;
  outline: none;

}

.achievements-slide__box {
  position: relative;
  display: block;
  height: 100%;
  padding: 75px 90px 75px;
  box-sizing: border-box;
  transition: background-color .2s ease-in-out;

  &:hover {
    background-color: #F1F1F1;

    .achievements-slide__number {
      color: #fff;
    }
  }

  @media (max-width: $viewport-desktop) {
    padding: 45px 55px 45px;
  }

  @media (max-width: $viewport-tablet-small) {
    padding: 25px 35px 30px;
  }

  @media (max-width: $viewport-mobile) {
    padding: 20px 25px 25px;
  }
}

.achievements-slide__title {
  display: inline-block;
  max-width: 73%;
  color: #797979;
  font-size: 1.3125rem;
  line-height: 1.80952381;

  @media (max-width: $viewport-desktop) {
    font-size: 1.125rem;
  }

  @media (max-width: $viewport-tablet-small) {
    font-size: 1rem;
    max-width: 70%;
  }

  @media (max-width: $viewport-mobile) {
    font-size: 0.875rem;
    max-width: 90%;
    position: relative;
    z-index: 1;
  }
}

.achievements-slide__number {
  position: absolute;
  top: 80px;
  right: 90px;
  font-family: Visuelt Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 8.125rem;
  line-height: .85;
  color: #F1F1F1;
  transition: color .2s ease-in-out;

  @media (max-width: $viewport-desktop) {
    top: 55px;
    right: 55px;
    font-size: 6.25rem;
  }

  @media (max-width: $viewport-tablet-small) {
    top: 35px;
    right: 30px;
    font-size: 5rem;
  }

  @media (max-width: $viewport-mobile) {
    font-size: 6rem;
    top: 20px;
    right: 20px;
  }
}

.achievements-slider-controls {

  @media (max-width: $viewport-mobile) {
    top: -35px;
  }
}
</style>
