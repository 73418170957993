<template>
  <div>
    <div v-for="section in data" :key="section.title">
      <h2 class="section-title section-title--no-description" v-if="section.title" v-html="section.title"></h2>
      <div class="shareholders" v-if="section.list && section.list.length">
        <div class="shareholder-item" v-for="item in section.list" :key="item.title">
          <div class="shareholder-item__img-container">
            <img :src="require(`@/assets/images/${item.img}.png`)" :width="item.imgWidth" :alt="item.title" class="shareholder-item__img">
          </div>
          <p class="shareholder-item__title">{{ item.title }}</p>
          <p class="shareholder-item__description">{{ item.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Shareholders-block",
  props: {
    data: Array
  }
}
</script>

<style lang="scss">
.shareholders {
  display: flex;
  margin-bottom: 130px;

  @media (max-width: $viewport-tablet-small) {
    margin-bottom: 100px;
  }

  @media (max-width: $viewport-mobile) {
    flex-wrap: wrap;
    margin-bottom: 70px;
  }
}

.shareholder-item {
  width: 50%;
  margin-right: 90px;

  @media (max-width: $viewport-tablet-small) {
    margin-right: 40px;
  }

  @media (max-width: $viewport-mobile) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 60px;
  }

  &:nth-child(2n) {
    margin-right: 0;
  }
}

.shareholder-item__img-container {
  position: relative;
  height: 190px;
  max-width: 460px;
  margin-bottom: 50px;
  border: 3px solid #ccc;
  box-sizing: border-box;

  @media (max-width: $viewport-mobile) {
    margin-bottom: 30px;
  }
}

.shareholder-item__img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 70%;
  transform: translate(-50%, -50%);
}

.shareholder-item__title {
  display: block;
  font-family: Visuelt Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.583333333;
}

.shareholder-item__description {
  margin-top: 15px;
  font-size: 0.875rem;
  line-height: 2.714285714;
  color: #000000;

  @media (max-width: $viewport-tablet-small) {
    line-height: 2.3;
  }
}
</style>
