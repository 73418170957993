<template>
  <div class="action-msg">
    <span class="action-msg__text">{{ data.text }}</span>
    <a :href="data.link" class="action-link action-msg__btn">{{ data.button }}</a>
  </div>
</template>

<script>
export default {
  name: "Cta-banner",
  props: {
    data: Object
  }
}
</script>

<style lang="scss">
.action-link {
  display: inline-block;
  padding: 10px 25px 12px;
  color: #fff;
  font-size: .75rem;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  background: var(--primary-red-color);
  border-radius: 70px;
  transition: background .15s ease-in-out;
  box-sizing: border-box;

  &:hover {
    background: #b42400;
  }
}

.action-msg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 38px 90px;
  background: linear-gradient(180deg, #010101 0%, #151515 100%);

  @media (max-width: $viewport-tablet) {
    padding: 30px 50px;
  }

  @media (max-width: $viewport-tablet-small) {
    padding: 30px 35px;
  }

  @media (max-width: $viewport-mobile) {
    padding: 30px;
    flex-wrap: wrap;
  }
}

.action-msg__text {
  display: inline-block;
  margin-right: 90px;
  font-size: 1.5rem;
  line-height: 1.583333333;
  color: #FFFFFF;

  @media (max-width: $viewport-tablet) {
    margin-right: 50px;
  }

  @media (max-width: $viewport-tablet-small) {
    margin-right: 50px;
    font-size: 1.125rem;
    line-height: 1.583333333;
  }
}

.action-msg__btn {
  min-width: 180px;

  @media (max-width: $viewport-mobile) {
    margin-top: 30px;
  }
}

</style>
