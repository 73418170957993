<template>
  <div>
    <h2 class="section-title section-title--with-slider">{{ title }}</h2>
    <div class="achievements-slider-container">
      <v-achievements-slider/>
    </div>
  </div>
</template>

<script>
import AchievementsSlider from "@/components/Slider/Achievements-slider"

export default {
  name: "Achievements-block",
  data() {
    return {
      title: 'Our achievements',
    }
  },
  computed: {
  },
  components: {
    'v-achievements-slider': AchievementsSlider
  },
}
</script>
