<template>
  <div>
    <section class="section-box section-box--black section-box--first">
      <div class="wrapper">
        <v-main-info :data="mainInfoData" class="main-info--no-visual"></v-main-info>
      </div>
    </section>
    <section class="section-box section-box--no-bot-padding">
      <div class="wrapper">
        <v-about-block :data="aboutBlockData"></v-about-block>
      </div>
    </section>
    <section class="section-box">
      <div class="wrapper">
        <v-companies-block :data="companiesBlock"></v-companies-block>
        <v-cta-banner :data="ctaBanner"></v-cta-banner>
      </div>
    </section>
    <section class="section-box">
      <div class="wrapper">
        <v-achievements-block></v-achievements-block>
      </div>
    </section>
    <section class="section-box">
      <div class="wrapper">
        <v-shareholders-block :data="shareholdersBlock"></v-shareholders-block>
      </div>
    </section>
  </div>
</template>

<script>
import MainInfo from '@/components/layout/Main-info/Main-info'
import AboutBlock from '@/components/layout/About/About-block'
import CompaniesBlock from '@/components/layout/Companies/Companies-block'
import CtaBanner from '@/components/layout/CallToAction/Cta-banner'
import AchievementsBlock from '@/components/layout/Achievements/Achievements-block'
import ShareholdersBlock from '@/components/layout/Shareholders/Shareholders-block'

export default {
  name: "About",
  metaInfo: {
    title: 'About ITernal Group – our story and information'
  },
  data() {
    return {
      mainInfoData: {
        title: '<span class="main-info-title__row">A one stop partner to build</span><span class="main-info-title__row main-info-title__row--highlighted">custom software</span>',
        subTitle: 'The story of ITernal Group started in 2019 with a merger of three IT outsource companies. The oldest of them was founded in 2004. We have wide expertise in more than 20 industries: adtech, martech, fintech, ERP, transport, logistics, ecommerce, and more. This offers great potential to companies that work with us. With our help, you can improve an existing software, scale fast or launch R&D centers with minimal efforts.',
        link: {
          text: 'Discuss your project',
          href: '#contact-info',
          description: 'We care about your users, profit and growth. We are ready to share our expertise.'
        },
        listTitle: 'We can do for you',
        list: [
          'Proofs of concept and MVPs for start-ups',
          'Software development for SMEs and large enterprises',
          'Set up R&D centers',
          'Advice on every step of M&As',
          'Hire, train, and provide professional development'
        ]
      },
      aboutBlockData: {
        title: 'The story of <span class="section-title__highlighted"> creation</span>',
        text: 'ITernal was founded in 2019 as a joint venture between UFuture and InSoft Partners. InSoft Partners is a Ukrainian IT investment management holding with more than a decade of experience and extensive track-record in the industry.',
      },
      companiesBlock: {
        title: 'The Core',
        list: [
          {
            link: 'www.rademade.com',
            img: 'rademade-logo',
            text: 'A European IT company that specializes in the creation of online marketplaces, mobile apps, ERP, and CRM. Rademade provides a full cycle of services: collection and analysis of requirements, the creation of custom software solutions, UX/UI design, QA, maintenance, and support.'
          },
          {
            link: 'lenal.eu',
            img: 'lenal-logo',
            text: 'A full cycle production company with a portfolio full of successful projects for the most interesting and largest companies in the Ukrainian market. Focusing on creating e-commerce platforms, B2B systems, and high-load projects, Lenal goes through all the stages to shape concepts into striking software solutions.'
          },
          {
            link: 'rozdoum.com',
            img: 'rozdoum-logo',
            imgClass: 'company-item__img--high',
            text: 'A software development company and a service provider that committed to providing customer-specific solutions in diverse domains. Rozdoum specializes in integrating CRM and SAP platforms, data migration, custom app development and licensing.'
          }
        ]
      },
      ctaBanner: {
        text: 'Want to become a part of ITernal? Let’s discuss that.',
        link: 'mailto:partners@iternal.group',
        button: 'Contact us'
      },
      shareholdersBlock: [
        {
          title: 'Our shareholders',
          list: [
            {
              img: 'ufuture-logo',
              imgWidth: '260',
              title: 'Ufuture',
              description: 'A holding company that integrates business and impact-investment projects. The company has a diversified portfolio of assets in the fields of IT, renewable energy, real estate, infrastructure, industry, and pharmaceuticals. UFuture’s assets are estimated at $550 million, and the total capitalization of the businesses it invested in is more than $1 billion.'
            },
            {
              img: 'insoft-logo',
              imgWidth: '183',
              title: 'InSoft',
              description: 'An investment management company that invests in IT business and provides M&A advisory services. Having a team with extensive experience in developing IT companies, InSoft Partners is involved in the operating activities of portfolio companies, which contributes to their rapid growth (through lead generation, changes in business processes, etc). Among the company’s counterparties are the famous American investment fund, Oak Tree Capital Management, and FinPoint/Rothschild & Co.'
            }
          ]
        },
        {
          title: '<span class="section-title__text section-title__text-highlighted">Our partners</span>',
          list: [
            {
              img: 'ucode-logo',
              imgWidth: '183',
              title: 'ucode',
              description: 'A unique and scaling IT-training program with campuses in Kyiv and Kharkiv. Ucode was developed based on the Challenge Based Learning methodology, which was initiated by Apple and used at Apple Developer Academy. The training is built on real challenges, overcoming which students alone or in teams find ways to solve everyday problems.'
            }
          ]
        }
      ]
    }
  },
  components: {
    'v-main-info': MainInfo,
    'v-about-block': AboutBlock,
    'v-companies-block': CompaniesBlock,
    'v-cta-banner': CtaBanner,
    'v-achievements-block': AchievementsBlock,
    'v-shareholders-block': ShareholdersBlock
  }
}
</script>

<style lang="scss">

</style>
